import React, { useState, useRef } from "react";
import { navigate } from "gatsby";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { animateScroll as scroll } from "react-scroll";
import * as Yup from "yup";
import * as styles from "./RakuuruForm.module.scss";
//  import axios from "axios";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//バリデーション
const ContactFormSchema = Yup.object().shape({
  companyname: Yup.string().min(2, "会社名が短すぎます").max(70, "会社名が長すぎます").required("会社名は必須項目です"),
  companyfurigana: Yup.string().min(2, "フリガナが短すぎます").max(70, "フリガナが長すぎます").required("フリガナは必須項目です"),
  name: Yup.string().min(2, "お名前が短すぎます").max(70, "お名前が長すぎます").required("お名前は必須項目です"),
  furigana: Yup.string().min(2, "フリガナが短すぎます").max(70, "フリガナが長すぎます").required("フリガナは必須項目です"),
  postal: Yup.string().matches(phoneRegExp, "郵便番号が正しくありません").required("郵便番号は必須項目です"),
  address: Yup.string().min(2, "所在地が短すぎます").required("所在地は必須項目です"),
  tel: Yup.string().matches(phoneRegExp, "電話番号が正しくありません").required("電話番号は必須項目です"),
  email: Yup.string().email("メールアドレスが正しくありません。").required("メールアドレスは必須項目です"),
  emailconfirm: Yup.string()
    .email("メールアドレスが正しくありません。")
    .oneOf([Yup.ref("email")], "メールアドレスが一致しません。")
    .required("メールアドレスの確認は必須項目です"),
  // message: Yup.string()
  //  .required('お問い合わせ内容は必須項目です'),
});

const RakuuruForm = () => {
  const [toggleState, setToggle] = useState(false);
  const myRef = useRef(null);
  const handleClick = () => {
    setToggle(!toggleState);
    // scroll.scrollToTop();
    var scrlPos = myRef.current.offsetTop;
    scroll.scrollTo(scrlPos);
    // myRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    // console.log(myRef);
  };

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  return (
    <div className={`${styles.RakuuruForm} ${toggleState ? styles.confirm : ""}`} ref={myRef}>
      <Formik
        initialValues={{
          "form-name": "RakuuruForm", // NetlifyFormで必要な値
          subject: "【ぶちうま産直市場のご利用申請がありました】",
          name: "",
          furigana: "",
          companyname: "",
          companyfurigana: "",
          postal: "",
          address: "",
          tel: "",
          email: "",
          emailconfirm: "",
          message: "",
          "bot-field": "", // NetlifyFormで必要な値
        }}
        validationSchema={ContactFormSchema}
        onSubmit={(values, actions) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "ContactForm", ...values }),
          })
            .then(() => {
              // alert('Success');
              navigate("/success_buchiumasanchoku");
              actions.resetForm();
            })
            .catch(() => {
              alert("Error");
            })
            .finally(() => actions.setSubmitting(false));
        }}
      >
        {({ errors, touched }) => (
          <Form id="RakuuruForm" data-netlify="true">
            <Field type="hidden" name="bot-field" /> {/* NetlifyFormで必要な値 */}
            <Field type="hidden" name="form-name" /> {/* NetlifyFormで必要な値 */}
            <Field type="hidden" name="subject" value={`【ぶちうま産直市場のご利用申請がありました】`} /> {/* NetlifyFormで必要な値 */}
            <div className={styles.RakuuruForm__item}>
              <label htmlFor="companyname">
                <span className={`${styles.RakuuruForm__title}`}>会社名</span>
                <Field name="companyname" className={styles.RakuuruForm__field} placeholder="株式会社チキンヒーロー" />
                {errors.companyname && touched.companyname ? <div className={styles.RakuuruForm__errormsg}>{errors.companyname}</div> : null}
              </label>
              <div className={styles.RakuuruForm__caution}>※法人格の無い買手様は屋号のみご記入ください。</div>
            </div>
            <div className={styles.RakuuruForm__item}>
              <label htmlFor="companyfurigana">
                <span className={`${styles.RakuuruForm__title}`}>フリガナ</span>
                <Field name="companyfurigana" className={styles.RakuuruForm__field} placeholder="チキンヒーロー" />
                {errors.companyfurigana && touched.companyfurigana ? <div className={styles.RakuuruForm__errormsg}>{errors.companyfurigana}</div> : null}
              </label>
              <div className={styles.RakuuruForm__caution}>※法人格のフリガナは不要です。</div>
            </div>
            <div className={styles.RakuuruForm__item}>
              <label htmlFor="postal">
                <span className={styles.RakuuruForm__title}>郵便番号</span>
                <Field name="postal" type="postal" className={styles.RakuuruForm__field} placeholder="000-0000" />
                {errors.postal && touched.postal ? <div className={styles.RakuuruForm__errormsg}>{errors.postal}</div> : null}
              </label>
            </div>
            <div className={styles.RakuuruForm__item}>
              <label htmlFor="address">
                <span className={styles.RakuuruForm__title}>所在地</span>
                <Field name="address" className={styles.RakuuruForm__field} placeholder="ささみ市黒かしわ町1番1号" />
                {errors.address && touched.address ? <div className={styles.RakuuruForm__errormsg}>{errors.address}</div> : null}
              </label>
              <div className={styles.RakuuruForm__caution}>※ビル名、階数、お部屋番号等もご記入ください。</div>
            </div>
            <div className={styles.RakuuruForm__item}>
              <label htmlFor="tel">
                <span className={styles.RakuuruForm__title}>電話番号</span>
                <Field name="tel" type="tel" className={styles.RakuuruForm__field} placeholder="000-000-0000" />
                {errors.tel && touched.tel ? <div className={styles.RakuuruForm__errormsg}>{errors.tel}</div> : null}
              </label>
              <div className={styles.RakuuruForm__caution}>※固定電話の番号をお持ちの方はそちらの番号をご記入ください。</div>
            </div>
            <div className={styles.RakuuruForm__item}>
              <label htmlFor="email">
                <span className={styles.RakuuruForm__title}>メールアドレス</span>
                <Field name="email" type="email" className={styles.RakuuruForm__field} placeholder="xxxx_1234@xxx.com" />
                {errors.email && touched.email ? <div className={styles.RakuuruForm__errormsg}>{errors.email}</div> : null}
              </label>
            </div>
            <div className={styles.RakuuruForm__item}>
              <label htmlFor="emailconfirm">
                <span className={styles.RakuuruForm__title}>メールアドレス（確認）</span>
                <Field name="emailconfirm" type="email" className={styles.RakuuruForm__field} placeholder="xxxx_1234@xxx.com" />
                {errors.emailconfirm && touched.emailconfirm ? <div className={styles.RakuuruForm__errormsg}>{errors.emailconfirm}</div> : null}
              </label>
            </div>
            <div className={styles.RakuuruForm__item}>
              <label htmlFor="name">
                <span className={styles.RakuuruForm__title}>代表者・ご担当者様氏名</span>
                <Field name="name" className={styles.RakuuruForm__field} placeholder="山口　太郎" />
                {errors.name && touched.name ? <div className={styles.RakuuruForm__errormsg}>{errors.name}</div> : null}
              </label>
              <div className={styles.RakuuruForm__caution}>※漢字・フルネームでお願いします。</div>
            </div>
            <div className={styles.RakuuruForm__item}>
              <label htmlFor="furigana">
                <span className={styles.RakuuruForm__title}>フリガナ</span>
                <Field name="furigana" className={styles.RakuuruForm__field} placeholder="ヤマグチ　タロウ" />
                {errors.furigana && touched.furigana ? <div className={styles.RakuuruForm__errormsg}>{errors.furigana}</div> : null}
              </label>
            </div>
            <div className={styles.RakuuruForm__item}>
              <div className={`${styles.RakuuruForm__title} ${styles.noafter}`}>備考</div>
              <Field
                name="message"
                component="textarea"
                className={styles.RakuuruForm__textarea}
                placeholder="例1）「会社名」は運営会社のもので、請求書は運営する店舗名で発行して欲しい（又その逆）&#13;&#10;例2）「所在地」は運営会社の住所であるが、請求書は店舗へ送付して欲しい（又その逆）&#13;&#10;例3）その他ご要望並びにご質問"
              ></Field>
              {/* {errors.message && touched.message ? <div className={styles.RakuuruForm__errormsg}>{errors.message}</div> : null} */}
            </div>
            <div className={styles.RakuuruForm__errormsg}>
              <div>
                <ErrorMessage name="companyname" />
              </div>
              <div>
                <ErrorMessage name="companyfurigana" />
              </div>
              <div>
                <ErrorMessage name="postal" />
              </div>
              <div>
                <ErrorMessage name="address" />
              </div>
              <div>
                <ErrorMessage name="tel" />
              </div>
              <div>
                <ErrorMessage name="email" />
              </div>
              <div>
                <ErrorMessage name="emailconfirm" />
              </div>
              <div>
                <ErrorMessage name="name" />
              </div>
              <div>
                <ErrorMessage name="furigana" />
              </div>
              {/* <div>
            <ErrorMessage name="message" />
          </div> */}
            </div>
            {toggleState ? (
              <div className={`${styles.RakuuruForm__btnList}`}>
                <button type="button" className={`${styles.RakuuruForm__btn} ${styles.grayBtn}`} onClick={() => handleClick()}>
                  <span>修正</span>
                </button>
                <button type="submit" className={styles.RakuuruForm__btn}>
                  <span>送信</span>
                </button>
              </div>
            ) : (
              <div className={`${styles.RakuuruForm__btnList}`}>
                <button type="button" className={styles.RakuuruForm__btn} onClick={() => handleClick()}>
                  <span>入力内容確認</span>
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RakuuruForm;
