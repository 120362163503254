// extracted by mini-css-extract-plugin
export var RakuuruForm = "RakuuruForm-module--RakuuruForm--3bAQk";
export var RakuuruForm__caution = "RakuuruForm-module--RakuuruForm__caution--2GT_G";
export var RakuuruForm__errormsg = "RakuuruForm-module--RakuuruForm__errormsg--2IBnw";
export var RakuuruForm__item = "RakuuruForm-module--RakuuruForm__item--kmxrq";
export var RakuuruForm__title = "RakuuruForm-module--RakuuruForm__title--1-f-R";
export var noafter = "RakuuruForm-module--noafter--3hy1d";
export var RakuuruForm__field = "RakuuruForm-module--RakuuruForm__field--27Wve";
export var RakuuruForm__textarea = "RakuuruForm-module--RakuuruForm__textarea--3nepZ";
export var RakuuruForm__btnList = "RakuuruForm-module--RakuuruForm__btnList--1yxh9";
export var grayBtn = "RakuuruForm-module--grayBtn--1dysM";
export var RakuuruForm__btn = "RakuuruForm-module--RakuuruForm__btn--3UGgm";
export var confirm = "RakuuruForm-module--confirm--1N03J";