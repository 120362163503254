import React from "react";
import Seo from "../components/seo";
// import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import * as styles from "../styles/RakuuruEntry.module.scss";
// import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { RakuuruLayout } from "../components/index";
// import { LowerHeader } from "../components/LowerPages/index"
import RakuuruForm from "../components/Forms/RakuuruForm.jsx";

const RakuuruEntry = () => {
  // const breakpoints = useBreakpoint();

  return (
    <RakuuruLayout className={`${styles.RakuuruEntry}`} pageName={`rakuuruentry`} title={`深川養鶏「ぶちうま産直市場」ご利用申請フォーム`}>
      <Seo title={`深川養鶏「ぶちうま産直市場」ご利用申請フォーム`} description={``} />
      <div className={`LowerWrapper`}>
        <div className={`LowerContent`}>
          <div className={`LowerContent__inner`}>
            <h1 className={`${styles.RakuuruEntry__title}`}>
              深川養鶏
              <br className="smBr" />
              「ぶちうま産直市場」
              <br />
              ご利用申請フォーム
            </h1>
            <div className={`LowerLead`}>
              <p>
                ぶちうま産直市場のご利用申請は下記フォームをご利用ください。
                <br />
                本会員への新規申込に際しましては、決済方法としてご用意している
                <br />
                「クロネコ掛け払い」への同時申込みも兼ねております。
                <br />
                お申込み後、ヤマトグループのヤマトクレジットファイナンス株式会社との間で
                <br />
                お客様情報を共有し所定の審査を行いますことをご了承願います。
                <br />
                個人情報保護方針については<a href="/privacy/">こちら</a>をご覧ください。
              </p>
            </div>
            <RakuuruForm />
          </div>
        </div>
      </div>
    </RakuuruLayout>
  );
};
export default RakuuruEntry;
